<template>
    <div class="country-select">
        <div class="country-select__dropdown">
            <div :class="`country-select__active link-flag-${lang}`" />
            <div class="country-select__dropdown-wrapper">
                <div class="country-select__dropdown-pointer" />
                <ul class="country-select__list">
                    <li v-for="link in filteredLinks" :key="link.countryName" class="country-select__item">
                        <NuxtLink
                            :to="domain + localePath(`/${link.link.replace(/^.+?[/]/, '')}`, link.countryCode)"
                            :class="`country-select__link link-flag-${link.countryCode}`"
                            rel="nofollow, noreferrer, noindex"
                            external
                        >
                            {{ link.countryName }}</NuxtLink
                        >
                    </li>
                    <li class="country-select__item">
                        <NuxtLink
                            :to="`${domain}/`"
                            class="country-select__link"
                            rel="nofollow, noreferrer, noindex"
                            external
                            >{{ allCountriesText }}</NuxtLink
                        >
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="multiLanguages.length" class="country-select__multilanguages">
            <NuxtLink
                v-for="link in multiLanguages"
                :key="link.label"
                :to="domain + link.link"
                class="mr-2"
                rel="nofollow, noreferrer, noindex"
                external
            >
                {{ link.label }}
            </NuxtLink>
        </div>
    </div>
</template>

<script setup lang="ts">
import footerData from '~/src/footerData.json';

const props = defineProps<{
    lang: string | string[] | undefined;
    type: string | string[] | undefined;
    multiLanguages: { label?: string; link?: string }[];
}>();

const domain = useRuntimeConfig().public.SITE_URL;

const localePath = useLocalePath();

const { countriesList, categorySlugs } = footerData;
const { type, lang, multiLanguages } = toRefs(props);

const isTransfersPage = categorySlugs.transfer.includes(type.value as string);

// Filter current selected country, if it's UK, is stays in list
// Then select correct link: to airports / transfers pages according to our current page type
const filteredLinks = computed(() => {
    return countriesList
        .filter((country) => country.countryCode !== lang.value || country.countryCode === 'uk')
        .map((country) => ({
            ...country,
            link: `${country.countryCode}`,
        }));
});

const allCountriesText = computed(
    () => countriesList.find(({ countryCode }) => countryCode === lang.value)?.allCountriesTitle || 'All countries'
);
</script>
<style lang="postcss" scoped>
@import '/assets/css/flags.css';

.country-select {
    @apply relative inline-flex;
    &__active {
        @apply cursor-pointer py-1 px-4 inline-block relative z-20;
        &[class*='link-flag']:before {
            @apply top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4;
        }
    }
    &__multilanguages a {
        @apply ml-2 cursor-pointer hover:underline;
    }
    &__dropdown {
        @apply relative inline-block;
        &:hover {
            .country-select__dropdown-wrapper {
                @apply block;
            }
        }
        &-wrapper {
            @apply p-4 absolute bottom-8 -left-6 bg-white border border-primary ring-4 ring-[#e9daef] ring-inset shadow hidden w-72;
            &:before {
                content: '';
                @apply absolute top-full left-0 right-0 h-10;
            }
        }
    }
    &__dropdown-pointer {
        @apply absolute bottom-1 left-[23px];
        &:before,
        &:after {
            content: ' ';
            @apply absolute top-full w-0 h-0 pointer-events-none;
        }
        &:before {
            border-color: #e9daef rgba(233, 218, 239, 0) rgba(233, 218, 239, 0);
            @apply border-[16px];
        }
        &:after {
            @apply ml-[6px] border-[10px] border-t-white border-transparent;
        }
    }
    &__list {
        @apply flex flex-wrap;
    }
    &__item {
        @apply w-1/2;
        &:nth-child(4n + 2),
        &:nth-child(4n + 3) {
            @apply bg-[#f9f4fb];
        }
    }
    &__link {
        @apply pl-6 text-primary text-xs leading-6 hover:font-bold;
    }
}
</style>
